import { graphql, navigate } from "gatsby";
import { Power2, TimelineMax, TweenLite } from "gsap/all";
// NEEDED FOR GSAP TO WORK ON PRODUCTION
import CSSPlugin from "gsap/CSSPlugin";
// @ts-ignore
const C = CSSPlugin;
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown/with-html";

import SEO from "../components/seo";
import { sendGA, onInitPageLoadTracking } from "../helpers/tracking";

import { IAnswer, IChoice, IChoices, IGraphQLData, IJob, IQuestion } from "../interfaces";
import choicesData from "../quiz/choices/data.json";
import choicesGeneral from "../quiz/choices/general.json";
import choicesMarketing from "../quiz/choices/marketing.json";
import choicesOps from "../quiz/choices/ops.json";
import choicesRh from "../quiz/choices/rh.json";
import choicesSales from "../quiz/choices/sales.json";
import questions from "../quiz/questions.json";

const Quiz = ({ data, location }: { data: any; location: any }) => {
  const concatedChoices = Object.assign(
    {},
    choicesData,
    choicesGeneral,
    choicesMarketing,
    choicesOps,
    choicesRh,
    choicesSales,
  );

  // STATE
  const [duration] = useState<number>(0.4);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const [currI, setCurrI] = useState<number>(0);
  const [group, setGroup] = useState<string>("general");
  const [currQ, setCurrQ] = useState<IQuestion>(questions[group][currI]);
  const [choices] = useState<IChoices>(concatedChoices);
  const [jobs, setJobs] = useState<{ [slug: string]: string }>({});
  const [switching, setSwitching] = useState<boolean>(false);

  // REFS
  const $page = useRef(null);
  const $questionTitle = useRef(null);
  const $bgLogo = useRef(null);
  const $choicesList = useRef(null);
  const $stepNum = useRef(null);
  const $backCta = useRef(null);

  // VAR
  const quizLength = 6;
  const choicesArr = choices[currQ.label];
  let isSwitching = false;

  useEffect(() => {
    enterAnimation();
    createJobsList();
    onInitPageLoadTracking();
  }, []);

  useEffect(() => {
    if (isMounted) {
      showQuestion(() => {
        isSwitching = false;
        setSwitching(isSwitching);
      });
    }
  }, [currQ]);

  const enterAnimation = () => {
    const titleNode = $questionTitle.current;
    const bgNode = $bgLogo.current;
    const itemNode = $choicesList.current.childNodes;

    const tl = new TimelineMax({
      delay: 0.2,
      onComplete: () => {
        setIsMounted(true);
      },
    });

    tl.set([titleNode, bgNode, itemNode], { alpha: 0 })
      .to($page.current, 0.1, { alpha: 1 })
      .to(bgNode, 0.3, { alpha: 1 })
      .fromTo(titleNode, 0.4, { y: -10 }, { alpha: 1, y: 0, ease: Power2.easeOut }, "-=0.1")
      .staggerFromTo(itemNode, 0.6, { y: -10 }, { alpha: 1, y: 0, ease: Power2.easeOut }, 0.1, "-=0.2");
  };

  const exitAnimation = (node: any, callback: any) => {
    TweenLite.to(node, duration, {
      alpha: 0,
      onComplete: () => {
        if (typeof callback === "function") {
          callback();
        }
      },
    });
  };

  const createJobsList = () => {
    const dataJobs = data.allJobDescriptionJson.edges;
    const list = dataJobs.reduce((acc: { [key: string]: string }, j: IGraphQLData<IJob>) => {
      const slug = j.node.slug;
      const path = j.node.path;

      acc[slug] = path;

      return acc;
    }, {});

    setJobs(list);
  };

  const hideQuestion = (callback: any) => {
    const titleNode = $questionTitle.current;
    const listNode = $choicesList.current;
    const itemNode = listNode.childNodes;

    const tl = new TimelineMax({
      onComplete: () => {
        if (typeof callback === "function") {
          callback();
        }
      },
      paused: true,
    });

    tl.to(listNode, 0, { className: "+=disabled" })
      .to(titleNode, 0.4, { y: -4, alpha: 0, ease: Power2.easeInOut })
      .to(itemNode, 0.4, { y: -10, alpha: 0, ease: Power2.easeInOut }, "-=0.3")
      .to(listNode, 0, { alpha: 0 })
      .play();
  };

  const showQuestion = (callback: any) => {
    const titleNode = $questionTitle.current;
    const listNode = $choicesList.current;
    const itemNode = listNode.childNodes;

    const tl = new TimelineMax({
      onComplete: () => {
        if (typeof callback === "function") {
          callback();
        }
      },
      paused: true,
    });

    tl.to(listNode, 0.1, { alpha: 1 })
      .to(titleNode, 0.3, { y: 0, alpha: 1, ease: Power2.easeInOut })
      .staggerFromTo(itemNode, 0.6, { alpha: 0, y: -6 }, { alpha: 1, y: 0, ease: Power2.easeInOut }, 0.1, "-=0.2")
      .to(listNode, 0, { className: "-=disabled" })
      .play();
  };

  const getChoiceList = (listChoices: IChoice[], answer?: IAnswer) =>
    listChoices.map((choice, i) => {
      const selected = answer && answer.choice === choice ? "selected" : "";

      choice.uniq_id = i;

      return (
        <li key={i} className={`choice-item ${selected}`} onClick={() => onClickChoice(choice)}>
          <span className="radio-box"></span>
          <ReactMarkdown className="choice-text text" source={choice.text} />
        </li>
      );
    });

  const onClickChoice = (choice: IChoice) => {
    if (isSwitching || switching) {
      return;
    }
    isSwitching = true;
    setSwitching(isSwitching);

    hideQuestion(() => {
      handleChoiceClick(choice);
    });
  };

  const handleChoiceClick = (choice: IChoice) => {
    let newAnswers;
    const ans = { coeff: currQ.coeff, choice };

    if (currI < answers.length) {
      newAnswers = [...answers];
      newAnswers.splice(currI, 1, ans);
    } else {
      newAnswers = [...answers, ans];
    }
    const newI = currI + 1;
    setAnswers(newAnswers);

    if (newAnswers.length < quizLength) {
      computeNextQuestion(newAnswers, newI);
      updateQuestionNumber(newI);
      handleBackCta(newI);
    } else {
      computeJob(newAnswers);
    }
  };

  const onClickBackCta = () => {
    if (isSwitching || switching) {
      return;
    }
    isSwitching = true;
    setSwitching(isSwitching);

    handleBackCta(currI - 1);
    hideQuestion(() => {
      handleBackClick();
    });
  };

  const handleBackCta = (i: number) => {
    if (i <= 0) {
      TweenLite.to($backCta.current, 0.3, { alpha: 0, x: 10, className: "+=disabled" });
    } else {
      TweenLite.to($backCta.current, 0.2, { alpha: 1, x: 0, className: "-=disabled" });
    }
  };

  const handleBackClick = () => {
    const newI = currI - 1;
    updateQuestionNumber(newI, 1);
    computePrevQuestion(newI);

    sendGA("click", {
      event_label: "LB - Question précédente",
    });
  };

  const updateQuestionNumber = (i: number, dir = -1) => {
    const y1 = -6 * dir;
    const y2 = 6 * dir;
    const node = $stepNum.current;
    const tl = new TimelineMax({
      paused: true,
    });
    tl.to(node, 0.4, { y: y1, alpha: 0 })
      .addCallback(() => {
        setCurrI(i);
        TweenLite.set(node, { y: y2 });
      })
      .to(node, 0.4, { y: 0, alpha: 1 })
      .play();
  };

  const computeNextQuestion = (answ: IAnswer[], i: number) => {
    if (i < 3) {
      setCurrQ(questions.general[i]);
    } else {
      computeGroupe(answ.slice(0, 3), i);
    }
  };

  const computePrevQuestion = (i: number) => {
    if (i < 3) {
      setCurrQ(questions.general[i]);
    } else {
      setCurrQ(questions[group][i - 3]);
    }
  };

  const computeGroupe = (answ: IAnswer[], i: number) => {
    const currGroup = getResult(answ, "group");

    setGroup(currGroup);
    setCurrQ(questions[currGroup][i - 3]);
  };

  const computeJob = (answ: IAnswer[]) => {
    const jobAnsw = answ.slice(3, quizLength);
    const job = getResult(jobAnsw, "job");
    const url = jobs[job];

    const ids = answ.map((e: any) => e.choice.uniq_id);
    const idsString = ids.join("-");

    sendGA("click", {
      event_label: `LB - ${group} - ${idsString}`,
    });

    exitAnimation($page.current, () => {
      navigate(url);
    });
  };

  const getResult = (answ: IAnswer[], result: "job" | "group") => {
    const groupResult = answ.reduce((acc: { [key: string]: number }, answer: IAnswer) => {
      const gp = answer.choice[result];

      if (gp) {
        gp.map((g) => {
          if (acc[g]) {
            acc[g] += answer.coeff;
          } else {
            acc[g] = answer.coeff;
          }
        });
      }

      return acc;
    }, {});

    const res = Object.keys(groupResult).reduce((a, b) => (groupResult[a] > groupResult[b] ? a : b));
    return res;
  };

  return (
    <>
      <SEO
        title="Quiz"
        description="Une fiche de poste, ça ne représente pas forcément la réalité d’un métier. Le quiz pour trouver quel métier en startup vous correspond."
        meta={{
          "og:image": "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/visuel-partage-jobs-en-startup.jpg",
          "og:image:height": "566",
          "og:image:secure_url": "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/visuel-partage-jobs-en-startup.jpg",
          "og:image:width": "1080",
          "og:title": "Le quiz | Quel job en startup est fait vous pour vous ?",
          "og:url": location.href,
        }}
      />
      <div ref={$page} className="quiz-page-container page-container" style={{ opacity: 0 }}>
        <div className="nav-question-container small-container">
          <div className="question-num">
            0
            <i ref={$stepNum} className="q-num">
              {currI + 1}
            </i>{" "}
            <span className="separator"></span> 06
          </div>
          <span
            ref={$backCta}
            className={`back-cta underlined-link ${currI > 0 ? "" : "disabled"}`}
            onClick={() => onClickBackCta()}
          >
            question précédente
          </span>
        </div>
        <div className="question-content container">
          <div ref={$bgLogo} className="bg-logo">
            <div></div>
            <div></div>
          </div>
          <h2 ref={$questionTitle} className="question-title title-text">
            {currQ.text}
          </h2>
          <div className="choices-list-container small-container">
            <ul ref={$choicesList} className="choices-list">
              {getChoiceList(choicesArr, currI < answers.length ? answers[currI] : undefined)}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quiz;

export const query = graphql`
  {
    allJobDescriptionJson(limit: 1000) {
      edges {
        node {
          path
          slug
        }
      }
    }
  }
`;
